<template>
	<div class="grid">
		<div class="col-12">
            <Loading v-if="loading"/>
			<div class="card" v-if="!loading">
                
                <h5>Marketplace {{integracao.nome}}</h5>
                <TabView >
                    <TabPanel header="Autenticação" >
                        <div class="flex inputGroup">
                            <div style="width: 90%" class="inputContainer">
                                <label>Nome</label>
                                <InputText v-model="this.nome" style="width: 100%"/>
                            </div>
                        </div>

                        <div class="flex inputGroup">
                            <div style="width: 90%" class="inputContainer">
                                <label>Usuário</label>
                                <InputText v-model="this.usuario"  style="width: 100%"/>
                            </div>
                        </div>                    

                        <div class="flex inputGroup"  v-if="nome_marketplace != 'ML'">
                            <div style="width: 90%" class="inputContainer">
                                <label>Access token</label>
                                <InputText v-model="this.access_token"  style="width: 100%"/>
                            </div>
                        </div>     

                        <div class="flex inputGroup">
                            <div style="width: 90%" class="inputContainer">
                                <label>CNPJ CD (Fulfillment)</label>
                                <InputText v-model="cnpj"  style="width: 100%"/>
                            </div>
                        </div> 
                        <div class="flex inputGroup" v-if="nome_marketplace == 'ML'">
                            <div style="width: 90%" class="inputContainer">
                                <label>Code (Mercado Livre)</label>
                                <InputText v-model="this.code_mercadolivre"  style="width: 100%"/>
                            </div>
                        </div>     

                        <div class="flex inputGroup">
                            <div style="width: 90%" class="inputContainer" v-if="nome_marketplace == 'ML'">
                                <label>Client ID (Mercado Livre)</label>
                                <InputText v-model="this.client_id_mercadolivre"  style="width: 100%"/>
                            </div>
                        </div>   

                        <div class="flex inputGroup">
                            <div style="width: 90%" class="inputContainer" v-if="nome_marketplace == 'ML'">
                                <label>Client Secret (Mercado Livre)</label>
                                <InputText v-model="this.client_secret_mercadolivre"  style="width: 100%"/>
                            </div>
                        </div>
                        <div v-if="this.client_id_mercadolivre != '' && nome_marketplace == 'ML'">   
                            <a target="_blank"
                                :href="'https://auth.mercadolivre.com.br/authorization?response_type=code&client_id='+ this.client_id_mercadolivre +'&redirect_uri=https://smarthub.centrion.com.br/mercado-livre'">
                                <button class="btn_mercado_livre">
                                    <img src="\images\logo_mercado_livre.jpg" id="logo_ml"><div class="txt_btn_ml">Gerar Code Mercado Livre</div> 
                                </button>
                            </a>
                        </div>

                        <div class="centro">
                            <Button label="Continuar" class="p-button-success btn_success" @click="salvarMarketplace()"  style="margin-right:10px"/> 
                            <Button label="Cancelar" class="p-button-danger btn_cancel" @click="cancelar()"/> 
                        </div>
                    </TabPanel>
                    <TabPanel header="Categorias">
                        <h4>Mapeamento de Categorias</h4>

                        <div class="grid" style="background-color:#eff6ff">
                            <div class="col-4">Categoria</div>
                            <div class="col-6">Código da categoria no E-commerce</div>
                            <div class="col-2">Ações</div>
                        </div>


                        <div class="grid" v-for="(categoria, index) in categorias" :key="index">
                            <div class="col-4">{{categoria.nome}}</div>
                            <div class="col-6"><a @click="modalBuscarCategoria(categoria.id)">vincular código</a></div>
                            <div class="col-2"><a>vincular código</a></div>
                        </div>
                    </TabPanel>
                </TabView>
			</div>
		</div>
	</div>
    <Dialog header="Buscar Categorias no Marketplace" v-model:visible="mostrarCategoriasMarketplace" >
        <div v-for="(categoria, index) in this.categorias_marketplace" :key="index" class="grid">
            <div class="col-6">{{categoria.name}}</div>
            <div class="col-6"><button @click="setarCodigoEcomerce(categoria.code)">OK</button></div>
        </div>
    </Dialog>

    <Toast position="top-left" />

</template>

<script>

import Api from '../service/api';
import Loading from '../components/Loading.vue';
import RadioButton from 'primevue/radiobutton';

	export default {
    data() {
        return {
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            api: "",
            token:"",
            empresa_id:0,
            loading: true,
            integracao:{},
            marketplace:{},
            categoria_selecionada:0,
            codigo_categoria_ecommerce:[],
            categorias:[],
            categorias_marketplace:[],
            nome:'',
            usuario:'',
            cnpj:'',
            access_token:'',
            mostrarCategoriasMarketplace:false,
            nome_marketplace:'',
            nomeColunas: [
                { nome: "Nome", value: "nome" }
            ],
            RadioButton
        };
    },
    produtosService: null,
    quantidadePaginas: 1,
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
        this.api = new Api();
    },
    mounted() {
        this.carregarMarketPlaceEmpresa();
        
        /*
        
        this.listarCategorias();
        this.listarCategoriasMarketplace();*/
    },
    methods: {
        listarCategorias() {
            this.loading = true;
            this.api.listarCategoriasMarketplace(this.token, this.empresa_id,this.$router.currentRoute.value.params.id).then(data => {
                data.data.forEach(element => {
                    this.codigo_categoria_ecommerce[element.id] = element.codigo_marketplace
                });
                this.categorias = data.data
                this.loading = false 
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        carregarMarketPlace() {
            this.api.selecionaMarketplaces(this.token, this.$router.currentRoute.value.params.id).then(data => {
                this.nome_marketplace = data.data.resposta.nome_hub;
                this.integracao.nome = data.data.resposta.nome
                this.loading = false;
            })
            
        },

        carregarMarketPlaceEmpresa() {
            this.loading = true;
            this.api.carregarIntegracao(this.token, this.empresa_id, this.$router.currentRoute.value.params.id).then(data => {

                if(!data.data){
                    this.carregarMarketPlace();
                    return;
                }
                this.integracao = data.data
                this.loading = false;
                this.nome = this.integracao.nome_integracao
                this.usuario = this.integracao.usuario
                this.access_token = this.integracao.token
                this.cnpj = this.integracao.cnpj

                this.code_mercadolivre = this.integracao.code_mercadolivre
                this.client_id_mercadolivre = this.integracao.client_id_mercado_livre
                this.client_secret_mercadolivre = this.integracao.client_secret_mercado_livre

                this.nome_marketplace = this.integracao.nome_hub
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
            this.loading = false;
        },

        listarCategoriasMarketplace() {
            this.loading = true;
            console.log(this.nome_marketplace) 
            if(this.nome_marketplace == "MadeiraMadeira"){
                this.api.listarCategoriasMadeira(this.access_token).then(data => {
                    this.categorias_marketplace = data.data
                    console.log(this.categorias_marketplace)
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
            }

        /*    this.api.listarCategoriasSkyhub().then(data => {
                this.categorias_marketplace = data.data
            }); */
            
        },

        setarCodigoEcomerce(codigo_ecommerce){
            this.api.cadastrarCategoriaMarketplace(1,codigo_ecommerce,this.integracao.id,this.categoria_selecionada).then(data =>{
                console.log(data)
                this.codigo_categoria_ecommerce[this.categoria_selecionada] = codigo_ecommerce
                this.mostrarCategoriasMarketplace = false;
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        setQuantidadePorPagina(quantidade) {
            this.quantidadePorPagina = quantidade;
            this.listarAnuncios();
        },

        modalBuscarCategoria(categoria){
            this.listarCategoriasMarketplace()
            this.categoria_selecionada = categoria
            this.mostrarCategoriasMarketplace = true;
        },

        salvarMarketplace(){
            this.api.alterarMarketplaceEmpresa({
                token_sistema: this.token,
                empresa_id: this.empresa_id,
                nome: this.nome,
                usuario: this.usuario,
                access_token: this.access_token,
                cnpj: this.cnpj,
                client_id_mercadolivre: this.client_id_mercado_livre,
                client_secret_mercadolivre: this.client_secret_mercadolivre,
                marketplace_id: this.$router.currentRoute.value.params.id
            }).then(data =>{
                console.log(data)
              this.$router.push({ name: 'integracoes' })
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        cancelar(){
             this.$router.push({ name: 'integracoes' })
        },
        fecharImportar(){
            this.mostrarImportar = false;
        },
    },
    components: { Loading }
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
<style scoped lang="css">
#logo_ml{
    width: 30px;
    float: left;
}
.txt_btn_ml{
    float: left;
    margin-top: 8px;
    color: #666;
    margin-left: 10px;
}
.btn_mercado_livre{
    border: #ccc solid 1px;
    background-color:#fff;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor:pointer
}
input{
    
}

.titulo_anuncio{
    display: inline-block; 
    width: 50%;
}
.botoes_topo{
    display: inline-block;
    width: 50%;
    text-align:right
}

.botao_salvar{
    margin-left:10px;
}

.inputGroup{
    margin-bottom:10px;
}

.btnSalvar{
    margin-right: 10px;
}
</style>
